<template>
    <BaseSection
        class="the-accordion-block"
        :class="largeHeader ? 'section--large-header' : ''"
        width="content"
    >
        <template
            v-if="header"
            v-slot:title
        >
            {{ header }}
        </template>
        <TheAccordion
            v-for="(item, i) in accordionItems"
            :key="`accordion-item-${i}`"
            :anchor-id="item.anchorId"
        >
            <template v-slot:header>{{ item.header }}</template>
            <!-- eslint-disable vue/no-v-html -->
            <div
                class="article"
                v-html="item.text"
            />
            <!-- eslint-enable vue/no-v-html -->
        </TheAccordion>
    </BaseSection>
</template>

<script setup>
const props = defineProps({
    content: {
        type: Object,
        required: true,
        default: () => {
            return {
                accordion: []
            };
        }
    }
});

const header = computed(() => {
    return props?.content?.header ?? '';
});

const largeHeader = computed(() => {
    return props?.content?.largeHeader ?? true;
});

const accordionItems = computed(() => {
    return props?.content?.accordion ?? [];
});
</script>

<style lang="less">
.the-accordion-block {
    .the-accordion__header {
        .typography-lead();
    }
}
</style>
